import * as React from "react";
import { Component, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Helper from "../utils/Helper";


import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl,
  eventImageBaseUrl,
} from "../config/constant";
import {
  setUserCurrLoc,
  getUserCurrLoc,
  getUser,
  getToken,
  removeUserSession,
} from "../utils/UserAuthenticate";
import Carousel, { Modal, ModalGateway } from 'react-images'

const Hangout_ph = () => {
  const [hangout, setHangout] = useState([]);
  const [n1, setN1] = useState();
  const [n2, setN2] = useState();
  const slider1 = useRef();
  const slider2 = useRef();
  const [slidderImages, setSliderImages] = useState([])
  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then((res) => {
      if (res.legalAge === "No") {
        window.location = "/homepage";
      }
    });
    if (!getUserCurrLoc()) {
      setUserCurrLoc();
    }
    getData();
    //removeUserSession();
  }, []);

  const getData = async () => {
    try {
      // setIsloading(true);
      // here Api call for Home page
      let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions);
      if (result) {
        result = await result.json();

        setHangout(result.response.result.hangout);
        let hangoutImgArrayByType = [];
      
        // Get the hangout types present in the result.response.result.hangout
        const validHangoutData = result.response.result.hangout.map(item => ({
          type: item.type,
          hangoutName: item.hangoutName
        }));
        
        // Organize hangout images by type and filter to match valid hangout types
        result.response.result.hangoutImgList.forEach(hangout => {
          const { type, ...rest } = hangout;
        
          // Find if the type exists in the valid hangout data
          const validHangout = validHangoutData.find(item => item.type === type);
        
          // Only proceed if the type exists in the valid hangout types
          if (validHangout) {
            // Find the index of the hangout type in the array
            const index = hangoutImgArrayByType.findIndex(item => item && item.type === type);
        
            const hangoutData = { ...rest, hangoutName: validHangout.hangoutName }; // Use hangoutName from validHangoutData
        
            if (index === -1) {
              // If the type is not found, create a new entry with hangoutName at the top level
              hangoutImgArrayByType.push({ type, hangoutName: validHangout.hangoutName, data: [hangoutData] });
            } else {
              // If the type exists, append the new image data to it
              hangoutImgArrayByType[index].data.push(hangoutData);
            }
          }
        });
        // Set the updated slider images to match the filtered hangout types
        setSliderImages(hangoutImgArrayByType);

      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (groupIndex, imageIndex) => {
    setSelectedGroupIndex(groupIndex);
    setSelectedImageIndex(imageIndex);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
console.log(slidderImages,"sliderImges")
  return (
    <div>
      <Slider
        asNavFor={n1}
        ref={(slider2) => setN1(slider2)}
        slidesToShow={2}
        rows={2}
        swipeToSlide={true}
        focusOnSelect={true}
        dots={true}
        speed={2000}
        autoplay={true}
      >
{slidderImages && slidderImages.map((group, groupIndex) => (
  <div key={groupIndex} className="slideHangOut">
    <div className="d_product_box">
      <figure className="d_product_img mb-0">
      {group.data
  .map((data, originalIndex) => ({ data, originalIndex })) // Map each item to an object with its data and original index
  .filter(({ data }) => data.isPin === 1) // Filter items where isPin is 1
  .map(({ data, originalIndex }, index) => ( // Map over filtered items
    <img
      key={index} // Use the filtered index for the key
      src={data.hangoutImage}
      alt={`Group ${group.hangoutName}`}
      onClick={() => openModal(groupIndex, originalIndex)} // Use the original index for modal opening
    />
  ))
}
      </figure>
      <p className="d_product_title">  {group.hangoutName.slice(0, 15) + (group.hangoutName.length > 15 ? '...' : '')}</p>
    </div>
  </div>
))}


      </Slider>

      {/* ModalGateway wraps the Modal to handle mounting and unmounting */}
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={closeModal}>
            <Carousel
              currentIndex={selectedImageIndex}
              views={
                slidderImages[selectedGroupIndex]?.data
                  ? slidderImages[selectedGroupIndex].data.map(hangoutItem => ({
                      source: hangoutItem.hangoutImage,
                      caption: hangoutItem.hangoutName || 'Default Caption'
                    }))
                  : []
              }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default Hangout_ph;