import React, { useEffect, useState, useContext } from 'react'
import '../style_web/styleDoolally.css'
import Review from '../Sliders/Review'
import Header from '../components/HeaderWeb'
import Footer from '../components/FooterWeb'
import Helper from '../utils/Helper'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import LoadingSpinner from '../components/spinner/LoadingSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import ModalComponent from '../components/FnbModalCheck'
import FnbContext from '../contextApi/FnbContext'
import { isDisableAddtoCartButton } from '../utils/Common'

import {
  getRequestOptions,
  apiBaseUrl,
  homePageApiUrl,
  fBDImageBaseUrl
} from '../config/constant'
import { setUserCurrLoc, getUserCurrLoc } from '../utils/UserAuthenticate'

const whatsOnTapImages=[{
  filename:'Alphonso Mead.png',
  itemName:'Alphonso Mead',
},
{
  filename:'Apple Cider.png',
  itemName:'Apple Cider',
} ,
{
  filename:'Belgian Witbier.png',
  itemName:'Belgian Witbier',
} ,
{
  filename:'Blueberry Cider.png',
  itemName:'Blueberry Cider',
} ,
{
  filename:'Coffee Orange Mead.png',
  itemName:'Coffee Orange Mead',
} ,
{
  filename:'English Brown Ale.png',
  itemName:'English Brown Ale',
} ,
{
  filename:'Hefeweizen.png',
  itemName:'Hefeweizen',
} ,
{
  filename:'Rauchbier.png',
  itemName:'Rauchbier',
} ,
{
  filename:'7.png',
  itemName:'Oatmeal Stout',
} 

]

const Beer = () => {
  const {
    beveragesItem,
    onAdd,
    beveragesCategory,
    addon,
    handleChangeIncDec,
    cartItems,
    resturantDetails,
    soldOutItems
  } = useContext(FnbContext)
  const [temp, setTemp] = useState([])
  const [extraItem, setExtraItem] = useState([])
  const [show, setShow] = useState(false)
  const handleClose = () => {setShow(false); handleClose2();setSelectedAddons([]) }
  const handleShow2 = () => setShow(true)
  const [error,setError]=useState('')
const [showpopup,setShowpopup]=useState(false)
  const [twitterView, settwitterView] = useState([])
  const [whatsOnTap, setwhatsOnTap] = useState([])
  const [location, setLocation] = useState([])
  const [hangout, setHangout] = useState([])
  const [whatHappingWeek, setwhatHappingWeek] = useState([])
  const [testimonial, setTestimonial] = useState([])
  const [isloading, setIsloading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('');
  const [addonItemSelectionMax,setAddonItemSelectionMax]=useState('');
  const [addonItemSelectionMin,setAddonItemSelectionMin]=useState('');
  useEffect(() => {
    Helper.checkCurrentUserLegalAge().then(res => {
      if (res.legalAge === 'No') {
        window.location = '/homepage'
      }
    })
    if (!getUserCurrLoc()) {
      setUserCurrLoc()
    }

    const modalData = Helper.getModalData()
    if (modalData) {
      setName(modalData.name)
      setEmail(modalData.email)
    }
    getData()
    //removeUserSession();
  }, [])

  const getData = async () => {
    try {
      setIsloading(true)
      // here Api call for Home page
      let result = await fetch(apiBaseUrl + homePageApiUrl, getRequestOptions)
      if (result) {
        result = await result.json()
        settwitterView(result.response.result.twitterView)
        setwhatsOnTap(result.response.result.whatsOnTap)
        setLocation(result.response.result.location)
        setHangout(result.response.result.hangout)
        setwhatHappingWeek(result.response.result.whatHappingWeek)
        setTestimonial(result.response.result.testimobial)
        setTimeout(() => {
          setIsloading(false)
        }, 500)
        // console.log(result.response);
      }
    } catch (error) {
      console.log('error', error)
    }
  }

const handleClose2=()=>{
  setShowpopup(false);

}


  const handleAdd = (item, index) => {
    setAddonItemSelectionMin(item?.addon[0]?.addon_item_selection_min)
    setAddonItemSelectionMax(item?.addon[0]?.addon_item_selection_max)
    if (item.addon && item?.addon?.length > 0) {
      // the addon array is not empty
      for (let i = 0; i < addon?.length; i++) {
        if (item.addon[0].addon_group_id === addon[i].addongroupid) {
          setExtraItem([addon[i]])
          setTemp(item)
          handleShow2()
          break // stop looping once the desired data is found
        }
      }
    } else {
      // the addon array is empty
      onAdd(item, index); 
    }
  }

  // paggination
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(3)

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const filteredItems = selectedFilter
    ? beveragesItem.filter(item => item.item_categoryid === selectedFilter)
    : beveragesItem
  const totalPages = Math.ceil(filteredItems?.length / itemsPerPage)
  const beveragesItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem)

  // end paggination

  // start filataration
  const handleFilterClick = item => {
    setSelectedFilter(prevFilter => {
      // If the same category is clicked again, clear the filter
      if (prevFilter === item.categoryid) {
        return null
      } else {
        return item.categoryid
      }
    })
  }
  // end filtration
  const [selectedAddons, setSelectedAddons] = useState([])
  // const onUpdateField = (e, addonItem) => {
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     setSelectedAddons([...selectedAddons, addonItem]);
  //   } else {
  //     setSelectedAddons(
  //       selectedAddons.filter(
  //         (addon) => addon.addonitemid !== addonItem.addonitemid
  //       )
  //     );
  //   }
  // };

  // const onUpdateField = addonItem => {
  //   if (selectedAddons === addonItem) {
  //     // If the addon is already selected, unselect it on double-click
  //     setSelectedAddons(null)
  //   } else {
  //     // Otherwise, select the addon
  //     setSelectedAddons([addonItem])
  //   }
  // }
  // const onUpdateField = (addonItem) => {
  //   if (selectedAddons.includes(addonItem)) {
  //     setSelectedAddons(selectedAddons.filter(item => item !== addonItem));
  //   } else {
  //     setSelectedAddons([...selectedAddons, addonItem]);
  //   }
  // };
  
  const onUpdateField = (addonItem) => {
    // Check if the item is already selected
    if (selectedAddons.some(item => item.id === addonItem.addonitemid)) {
      // If selected, remove it from the selectedAddons array
      setSelectedAddons(selectedAddons.filter(item => item.id !== addonItem.addonitemid));
    } else {
      // If not selected, check if the current selection is less than the max allowed
      if (selectedAddons.length < (addonItemSelectionMax ?? 0)) {
        // Add the item if less than max items are selected
        const addonspayload = {
          id: addonItem?.addonitemid,
          name: addonItem?.addonitem_name,
          group_name: extraItem[0]?.addongroup_name,
          price: addonItem?.addonitem_price,
          group_id: extraItem[0]?.addongroupid,
          quantity: "1"
        };
        setSelectedAddons([...selectedAddons, addonspayload]);
      } else {
        // Set an error message if trying to select more than allowed
        setError(`You can only select up to ${addonItemSelectionMax} options.`);
        setShowpopup(true)
      }
    }
  };


  return (
    <>
      <ModalComponent />
      <div className='container-fluid'>
        <Header />
        <section className='d_main_panel' style={{ margin: '0 1%' }}>
          <div className='container-fluid'>
            <div className='row g-5'>
              <div className='col-lg-3 mb-4'>
                <div
                  className='d_left_pan box_padding gray_bg_color'
                  style={{ height: '842px', overflow: 'scroll' }}
                >
                  <div className='d_title_box'>
                    <h5 className='d_main_title'>What's On Tap </h5>
                    <p className='d_main_sub_title'>
                      {/* Citrusy witbiers, dark decadent stouts, fruity ciders */}
                      Order craft brews online, home delivery across Mumbai & Pune within 2 hours.
                      {/* <span className='d_line'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span> */}
                    </p>
                  </div>
                  <div className='row gy-2 gx-4'>
                    {/* here the Api render on home page for whats on tap */}
                    {whatsOnTapImages.slice(0, 8).map((item, index) => (
                      <div key={index} className='col-6' id='whatsonTap'>
                        <div className='d_product_box'>
                          <figure className='d_product_img mb-0' key={index}>
                            <img
                            src={process.env.PUBLIC_URL+'./WoodcutBeersLatest/'+item.filename}
                              // src={fBDImageBaseUrl + 'thumb/' + item.filename}
                              alt=''
                            />
                          </figure>
                          <p className='d_product_title'>{item.itemName}</p>
                          <a className='d_overlay'>
                            <p>Explore More</p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='col-lg-6 mb-4 '>
                <div className=' d_midile_panal gray_bg_color'  style={{ height: '192px', marginBottom: '15px' }}>
                  {/* <Location/> */}
                  {/* <h3 className="d_main_title box_padding">Our Locations</h3> */}
                  <div className='location-section '>
                    <div className='d-flex justify-content-around pt-3'>
                      <div className='adj-location' id='circle-effect'>
                        <img
                          src={process.env.PUBLIC_URL + '/img/Bevrages_icon.png'}
                        />
                        <h5> Beverages</h5>
                      </div>
                      <div className='adj-location'>
                        <Link to='/food'>
                          <img
                            src={process.env.PUBLIC_URL + '/img/Food_icon.png'}
                          />
                          <h5> Food </h5>
                        </Link>
                      </div>
                      <div className='adj-location'>
                        <Link to='/merchandise'>
                          <img
                            src={process.env.PUBLIC_URL + '/images/merch.png'}
                          />
                          <h5> Merchandise</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box_padding gray_bg_color' style={{height:"634px"}}>
                  <div className='h-food-menuu ' style={{ padding: '2%' }}>
                    <p className=' d_main_title '>
                      <h4>Menu</h4>
                      <h6 className='d_main_sub_title'>
                        Craft Beverages and Ciders
                      </h6>{' '}
                    </p>
                    {resturantDetails ? (
                      <div className='location_resturant'>
                        <p>
                          <FontAwesomeIcon icon={faLocationDot} />
                          {resturantDetails.restaurantName}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='food_items'style={{height:"500px"}}>
                  {/* beveragesItem */}
                    {filteredItems ? (
                      filteredItems.map((item, id) => (
                        
                        <div
                        key={id}
                        className="food-menu-container"
                      >
                        <div className="food-details">
                          <h5>{item.itemname}</h5>
                          <p className="description">{item.itemdescription}</p>
                          <h6>{item.price}</h6>
                        </div>
                      
                        <div className="image-container">
                          <img
                            src={item.item_image_url ? item.item_image_url : process.env.PUBLIC_URL + "/images/pro7.png"}
                            alt=""
                          />
                          <br />
                          {soldOutItems.includes(item.itemid) ? (
                            <div className="sold-out">Sold Out</div>
                          ) : cartItems.find((cartItem) => cartItem.itemid === item.itemid) ? (
                            <div className="quantity-container">
                              <span onClick={() => handleChangeIncDec(item, -1)}>-</span>
                              <input
                                type="number"
                                value={cartItems.find((cartItem) => cartItem.itemid === item.itemid).qty}
                                readOnly
                              />
                              <span onClick={() => handleChangeIncDec(item, 1)}>+</span>
                            </div>
                          ) : (
                            <button onClick={() => handleAdd(item, id)}>Add</button>
                          )}
                        </div>
                      </div>
                      ))
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                  {/* <div className='paggination-btn'>
                    <button onClick={handleNextPage}>Next</button>
                    <button onClick={handlePrevPage}>Prev</button>

                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                  </div> */}
                </div>
              </div>

              {/*  category section */}
              <div className='col-lg-3'>
                <div className='d_right_panal box_padding'  style={{overflow:"scroll",height:"500px"}}>
                  <div className='d_title_box'>
                    <h5 className='d_main_title'>Categories</h5>
                  </div>
                  <ul className='catgry-beer'>
                    {/* <li className="d-flex">
                      <img
                        src={process.env.PUBLIC_URL + "/images/beer_cat.png"}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginLeft: "-24px",
                          padding: "2%",
                        }}
                      />
                      Ales
                    </li>

                    <li>
                      {" "}
                      <img
                        src={process.env.PUBLIC_URL + "/images/beer_cat2.png"}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginLeft: "-24px",
                          padding: "2%",
                        }}
                      />
                      Lagers
                    </li> */}
                    {beveragesCategory?.map((item, index) => (
                      <li
                        key={item.categoryid}
                        id='fnb-category'
                        className={
                          selectedFilter === item.categoryid ? 'active' : ''
                        }
                        onClick={() => handleFilterClick(item)}
                      >
                        {/* <img src={item.category_image_url} alt="img"/> */}
                        {item.categoryname}
                      </li>
                    ))}
                    {/* <li>Wheat Beers</li>
                    <li>Ciders</li>

                    <li>Mocktails</li>
                    <li>Juices</li>
                    <li>Kombucha</li>
                    <li>Milkshakes</li> */}
                  </ul>
                </div>

                {/* review section  */}
                <div className=' d_main_panel_review '>
                  <div className='d_right_panal box_padding'    style={{ height: '327px' }}>
                    <div className='d_title_box'>
                      <h5 className='d_main_title'>What our customers say</h5>
                      <p className='d_main_sub_title'>
                        What Our Happy Client Says
                        {/* <span className='d_line'></span>
                        <span className='d_round'></span> */}
                      </p>
                      <Review />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      {/* modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-bottom'
      >
        <Modal.Header closeButton>
          <h5>{temp.itemname}</h5>

          {/* <Button onClick={handleClose2} id="btn-modal-footer" style={{backgroundColor:"#a2c760" , width:"75px"}}>Add</Button> */}
          <br />
        </Modal.Header>
  

        <Modal.Body>
        <div
  className="row"
  style={{ overflow: "scroll", height: "400px" }}
>
  {extraItem?.map((addonGroup, index) => (
    <div key={index}>
      <p className="d_main_title d_box">
        {addonGroup.addongroup_name}
      </p>
      <h6>You can choose upto {addonItemSelectionMax} {addonItemSelectionMax>1?"options":"option"} </h6>
      {addonGroup?.addongroupitems?.map((addonItem, index) => (
        <div className="addOnItems_block" key={index}>
          <div>
          <input
  type="checkbox"
  value={addonItem.addonitem_name}
  checked={selectedAddons.some(item => item.id === addonItem.addonitemid)} // Check using some() to match based on ID
  onChange={() => onUpdateField(addonItem)} // Handle change with onUpdateField
  className="Addoncustom-checkbox"
/>
            
            <label style={{ paddingLeft: "10px" }}>
              {addonItem.addonitem_name}
            </label>
          </div>

          <span>Rs {addonItem.addonitem_price}</span>
        </div>
      ))}
    </div>
  ))}
</div>

        </Modal.Body>
        <Modal.Footer>
          <button
            variant='secondary'
            onClick={() => {
              if(!selectedAddons?.length>0){
                setError(`select atleast  ${addonItemSelectionMin} options.`);
                setShowpopup(true)  
return
              }
             
              onAdd(temp, selectedAddons)
              handleClose();
              setSelectedAddons([])
            }}
            className='add-on-more-btn'
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showpopup} onHide={handleClose2}  centered backdrop="static" style={{width:"auto"}} >

<Modal.Body className='Mob_Otp_popup' style={{width:"333px" }} >

  <div  style={{padding:"0"}}>
      <img src={process.env.PUBLIC_URL+'/images/icons/cross.png'} alt="cross" onClick={handleClose2} />
   
<p style={{color:"red"}}>{error}</p>
    
  </div>


</Modal.Body>
</Modal>

      {/* <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-bottom"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <h3 className="" style={{ width: "96%" }}>
            Belgian witbier
          </h3>

          <button
            className="z"
            onClick={handleClose}
            id="btn-modal-footer"
            style={{
              backgroundColor: "#a2c760",
            }}
          >
            Add
          </button>
        </Modal.Header>

        <Modal.Body>
          <div
            className="row g-5"
            style={{ overflow: "scroll", height: "400px" }}
          >
            <div className="col-2">
              <div className="row" id="myDiv">
                <div className="sidebar active" onClick={handleClick}>
                  <a className="" href="#beer-cider">
                    Beer cider
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="sidebar" onClick={handleClick}>
                  <a className=" " href="#wheat-beer">
                    Wheat Beer
                  </a>
                </div>
              </div>
            </div>
            <div className="col-10">
              <div
                className="d-flex justify-content-around"
                style={{
                  border: "1px solid #d4d4d4",
                  borderRadius: "5px",
                  boxShadow: "2px 5px 5px 5px #f5f5f5",
                  marginBottom: "2%",
                  padding: "2%",
                }}
              >
                <div>
                  <p className="d_main_title d_bo">Beer Cider</p>
                  <h6>you can choose any one option </h6>
                </div>

                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L </label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>

                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L </label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>

                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L</label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>
                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L </label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-around mt-4"
                style={{
                  border: "1px solid #d4d4d4",
                  borderRadius: "5px",
                  boxShadow: "2px 5px 5px 5px #f5f5f5",
                  marginBottom: "2%",
                  padding: "2%",
                }}
              >
                <div>
                  <p className="d_main_title d_bo">Wheat Beer </p>
                  <h6>you can choose any one option </h6>
                </div>

                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L </label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>

                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L </label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>

                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L</label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>
                <div className="col-2">
                  <div class=" custom-control ">
                    <input type="checkbox" />
                    <label class="custom-control-label">1L </label>
                    <label class=" adonPrice">Rs 500</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  )
}

export default Beer
