import React,{useEffect, useState} from 'react'
import { SEARCH_KEY, apiBaseUrl, eventCategoryEventListApiUrl, getRequestOptions, perPageShowData } from '../config/constant';
import { useNavigate } from 'react-router-dom';

const Search=(props)=> {
const [ eventlist,setEventlist]=useState([])
const [ispage, setIspage] = useState(1);
    const getData = async () => {
        try {
          // here Api call for Category Page Data
          let currentcateSlug =  "";
          let result = await fetch(
            apiBaseUrl +
              eventCategoryEventListApiUrl +
              "?cateSlug=" +
              currentcateSlug +
              "&page=" +
              ispage +
              "&perPage=" +
              perPageShowData,
            getRequestOptions
          );
          if (result) {
            result = await result.json();   
            if (result.response.result.eventList.length > 0) {
              setEventlist([...eventlist, ...result.response.result.eventList]);
            } 
          
          }
        } catch (error) {
        //  alert("Error while loading data. Try again later.");
        }
      };
      useEffect(()=>{
        getData()
      },[])
    const navigate=useNavigate()
    const [filteredData, setFilteredData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const placeholders = [
        "Discover Workshops",
        "Order Fries",
        "Order Dips",
        "Order Apple Cider",
        "Buy Craft beer",
        "Buy Coasters",
        "Try Chicken Ghee Roast",
      ];
      const [counter, setCounter] = useState(0);
      const [placeholder, setPlaceholder] = useState(placeholders[counter]);
    const findMostMatchedItem = (items, query) => {
      const lowercaseQuery = query.toLowerCase();
      let mostMatchedItem = null;
      let highestMatchCount = 0;
    
      for (const item of items) {
        const matchCount = calculateMatchCount(
          item.eventName.toLowerCase(),
          item.eventDescription.toLowerCase(),
          lowercaseQuery
        );
    
        if (matchCount > highestMatchCount) {
          highestMatchCount = matchCount;
          mostMatchedItem = item;
        }
      }
    
      return mostMatchedItem;
    };
    const calculateMatchCount = (name, description, query) => {
        const nameMatchCount = calculateStringMatchCount(name, query);
        const descriptionMatchCount = calculateStringMatchCount(description, query);
      
        return Math.max(nameMatchCount, descriptionMatchCount);
      };
      const calculateStringMatchCount = (str1, str2) => {
        let count = 0;
        const minLength = Math.min(str1.length, str2.length);
      
        for (let i = 0; i < minLength; i++) {
          if (str1[i] === str2[i]) {
            count++;
          } else {
            break;
          }
        }
      
        return count;
      };
    const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(value);
    
      const filtered = eventlist.filter(item =>
        item.eventName.toLowerCase().includes(value.toLowerCase()) ||
        item.eventDescription.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    };
  const handleSearch = (event) => {
    event.preventDefault();
  
    if (filteredData.length > 0) {
      const mostMatchedItem = findMostMatchedItem(filteredData, inputValue);
  
      if (mostMatchedItem) {
        navigate("/events");
      }
    }else if(SEARCH_KEY.EVENT.includes(inputValue.toLowerCase())){
      navigate("/events");
    }else if (SEARCH_KEY.FNB_BEER.includes(inputValue.toLowerCase())){
      navigate("/beer");
    }else if (SEARCH_KEY.FNB_FOOD.includes(inputValue.toLowerCase())){
      navigate("/food");
    }else if (SEARCH_KEY.FNB_MERCHANDISE.includes(inputValue.toLowerCase())){
      navigate("/merchandise");
    }else{
      navigate("/events");
    }
  };
    // serachbar

  
    useEffect(() => {
      const interval = setInterval(() => {
        setCounter((counter + 1) % placeholders.length);
      }, 3000);
      return () => clearInterval(interval);
    }, [counter]);
  
    useEffect(() => {
      setPlaceholder(placeholders[counter]);
    }, [counter]);
    // end
  return (
    <>
    
    {/* <form className=" d-flex search_order ds-none " onSubmit={handleSearch}>
        <input
          type="text"
          className=""
          // placeholder="Discover workshops, Order Fries, Order Dips, Buy Craft beer"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
        />
        <button type="submit" style={{backgroundColor: "#a2c76000"}}>
          <img
            src={process.env.PUBLIC_URL + "/images/icons/search-icon.png"}
            alt="search-icon"
  
          />
        </button>
      </form> */}
    
    </>
  )
}

export default Search;