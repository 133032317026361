import React, { useContext, useState, useEffect } from 'react'
import Header from '../components/HeaderMob'
import Footer from '../components/FooterMob'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
import FoodOrderBanner from '../Sliders/foodOrderBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import FnbContext from '../contextApi/FnbContext'
import ModalComponent from '../components/FnbModalCheck'
import { isDisableAddtoCartButton } from '../utils/Common'
import Search from '../components/search'

const Beer = () => {
  const {
    beveragesItem,
    onAdd,
    beveragesCategory,
    addon,
    handleChangeIncDec,
    cartItems,
    resturantDetails,
    soldOutItems
  } = useContext(FnbContext)
  const placeholders = [
    'Discover Workshops',
    'Order Fries',
    'Order Dips',
    'Order Apple Cider',
    'Buy Craft beer',
    'Buy Coasters',
    'Try Chicken Ghee Roast'
  ]
  const [counter, setCounter] = useState(0)
  const [placeholder, setPlaceholder] = useState(placeholders[counter])
  const [error, setError] = useState('')
  const [showpopup, setShowpopup] = useState(false)
  const handleClose = () => {
    setShowpopup(false);
    
  }
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter + 1) % placeholders.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [counter])

  useEffect(() => {
    setPlaceholder(placeholders[counter])
  }, [counter])
  // end



  // console.log(beveragesItem, "ll");
  const [selectedAddons, setSelectedAddons] = useState([])
  const [temp, setTemp] = useState([])
  const [extraItem, setExtraItem] = useState([])
  const [show2, setShow2] = useState(false)
  const handleClose2 = () => {setShow2(false);handleClose();setSelectedAddons([])}
  const handleShow2 = () => setShow2(true)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [beveragesItemList, setBeveragesItemList] = useState(beveragesItem)
  const [addonItemSelectionMax, setAddonItemSelectionMax] = useState('')
  const [addonItemSelectionMin, setAddonItemSelectionMin] = useState('')

  const handleFilterClick = item => {
    setSelectedFilter(prevFilter => {
      // If the same category is clicked again, clear the filter
      if (prevFilter === item.categoryid) {
        return null
      } else {
        return item.categoryid
      }
    })
  }
  const handleAdd = (item, index) => {
    setAddonItemSelectionMin(item?.addon[0]?.addon_item_selection_min)
    setAddonItemSelectionMax(item?.addon[0]?.addon_item_selection_max)
    if (item.addon && item.addon.length > 0) {
      // the addon array is not empty
      for (let i = 0; i < addon.length; i++) {
        if (item.addon[0].addon_group_id === addon[i].addongroupid) {
          setExtraItem([addon[i]])
          setTemp(item)
          handleShow2()
          break // stop looping once the desired data is found
        }
      }
    } else {
      // the addon array is empty
      onAdd(item, index)
      handleChangeIncDec(item, 1)
    }
  }

  const onUpdateField = addonItem => {
    // Check if the item is already selected
    if (selectedAddons.includes(addonItem)) {
      // If selected, remove it from the selectedAddons array
      setSelectedAddons(selectedAddons.filter(item => item !== addonItem))
    } else {
      // If not selected, check if the current selection is less than 3
      if (selectedAddons.length < addonItemSelectionMax ?? 0) {
        // Add the item if less than 3 items are selected
        setSelectedAddons([...selectedAddons, addonItem])
      } else {
        // Optionally, you can alert the user that only 3 selections are allowed

        setError(`You can only select up to ${addonItemSelectionMax} options.`)
        setShowpopup(true)
      }
    }
  }

  return (
    <>
      <ModalComponent />
      <div className='container-fluid d_margin mb-4 pb-4'>
        <Header />
        <Search />

        <div className=' ds-none  home-1st-col d-flex justify-content-around poppins  mt-2 '>
          <p id='circle-effect'>
            <img
              src={process.env.PUBLIC_URL + '/img/Bevrages_icon.png'}
              className='h-1st-col'
            />
            <br />
            Beverages
          </p>

          <p>
            <Link to='/food'>
              <img
                src={process.env.PUBLIC_URL + '/img/Food_icon.png'}
                className='h-1st-col'
              />
              <br />
              Food
            </Link>
          </p>

          <p>
            <Link to='/merchandise'>
              <img
                src={process.env.PUBLIC_URL + '/images/merch.png'}
                className='h-1st-col'
              />
              <br />
              Merchandise
            </Link>
          </p>
        </div>

        {/* body */}
        {/* <div className="d_main_title mt-4" style={{ paddingLeft: "3%" }}>
          Recommendation and best sellers
          <p className="d_main_sub_title">
            Crowd favourites , post hits, latest pics
          </p>
        </div>
        <FoodOrderBanner /> */}
        <div className='h-food-menuu ' style={{ padding: '2%' }}>
          <p className=' d_main_title '>
            Menu
            <h6 className='d_main_sub_title'>Craft beer and ciders</h6>
          </p>
          {resturantDetails ? (
            <div className='location_resturant' id='mob_res_loc'>
              <p style={{ fontSize: '10px' }}>
                <FontAwesomeIcon icon={faLocationDot} />
                {resturantDetails.restaurantName}
              </p>
            </div>
          ) : (
            ''
          )}
        </div>

        <Dropdown className='mb-2'>
          <Dropdown.Toggle
            variant='ffdsfdsfd'
            id='dropdown-split-basic'
            style={{ width: '100%', border: '1px solid #A2C760' }}
          >
            Categories
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: '100%' }}>
            {beveragesCategory?.map((item, index) => (
              <Dropdown.Item>
                <li
                  key={item.categoryid}
                  id='fnb-category'
                  className={selectedFilter === item.categoryid ? 'active' : ''}
                  onClick={() => handleFilterClick(item)}
                >
                  {item.categoryname}
                </li>
              </Dropdown.Item>
            ))}{' '}
          </Dropdown.Menu>
        </Dropdown>
        <div className='mb-4'>
          {beveragesItem?.map((item, id) => (
            <div key={id} className='food-menu-container'>
              <div className='food-details'>
                <b>{item.itemname}</b>
                <p className='description'>{item.itemdescription}</p>
                <h6>{item.price}</h6>
              </div>

              <div className='image-container'>
                <img
                  src={
                    item.item_image_url
                      ? item.item_image_url
                      : process.env.PUBLIC_URL + '/images/pro7.png'
                  }
                  alt=''
                />
                <br />
                {soldOutItems.includes(item.itemid) ? (
                  <div className='sold-out'>Sold Out</div>
                ) : cartItems.find(
                    cartItem => cartItem.itemid === item.itemid
                  ) ? (
                  <div className='quantity-container'>
                    <span onClick={() => handleChangeIncDec(item, -1)}>-</span>
                    <input
                      type='number'
                      value={
                        cartItems.find(
                          cartItem => cartItem.itemid === item.itemid
                        ).qty
                      }
                      readOnly
                    />
                    <span onClick={() => handleChangeIncDec(item, 1)}>+</span>
                  </div>
                ) : (
                  <button onClick={() => handleAdd(item, id)}>Add</button>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* <div className="paggination-btn">
<button onClick={handleNextPage}>Next</button>
<button onClick={handlePrevPage}>Prev</button>
     
      <p>Page {currentPage} of {totalPages}</p>
</div> */}
        {/* <div
          class="d-flex justify-content-between food-menu"
          style={{
            border: "1px solid #d4d4d4",
            borderRadius: "5px",
            boxShadow: "2px 5px 5px 5px #f5f5f5",
            marginBottom: "2%",
          }}
        >
          <div>
            <h5> Belgian witbier</h5>
            <p style={{ fontSize: "14px", color: "grey" }}>
              
              The witbier style, also known as bière blanche in the French also
              known as bière blanche in the French
            </p>
            <h6>1 Litre = Rs 500</h6>
          </div>

          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/pro1.png"}
              style={{ width: "160px", height: "140px", marginBottom: "-10px" }}
            />
            <br />
            <button
              style={{
                width: "100px",
                backgroundColor: "#edf7dd",
                border: "none",
                outline: "none",
                marginLeft: "20%",
                color: "#759246",
                borderRadius: "5px",
              }}
            >
              <strong>Add</strong>
            </button>
          </div>
        </div>
        <div
          class="d-flex justify-content-between food-menu mb-4"
          style={{
            border: "1px solid #d4d4d4",
            borderRadius: "5px",
            boxShadow: "2px 5px 5px 5px #f5f5f5",
            marginBottom: "2%",
          }}
        >
          <div>
            <h5> Belgian witbier</h5>
            <p style={{ fontSize: "14px", color: "grey" }}>
              
              The witbier style, also known as bière blanche in the French also
              known as bière blanche in the French
            </p>
            <h6>1 Litre = Rs 500</h6>
          </div>

          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/pro1.png"}
              style={{ width: "160px", height: "140px", marginBottom: "-10px" }}
            />
            <br />
            <button
              style={{
                width: "100px",
                backgroundColor: "#edf7dd",
                border: "none",
                outline: "none",
                marginLeft: "20%",
                color: "#759246",
                borderRadius: "5px",
              }}
            >
              <strong>Add</strong>
            </button>
          </div>
        </div> */}

        <Footer />

        <Modal
          show={show2}
          onHide={handleClose2}
          aria-labelledby='contained-modal-title-bottom'
        >
          <Modal.Header closeButton>
            <h5>{temp.itemname}</h5>

            {/* <Button onClick={handleClose2} id="btn-modal-footer" style={{backgroundColor:"#a2c760" , width:"75px"}}>Add</Button> */}
            <br />
          </Modal.Header>

          <Modal.Body>
            <div
              className='row'
              style={{ overflow: 'scroll', height: '400px' }}
            >
              {extraItem?.map((addonGroup, index) => (
                <div key={index}>
                  <p className='d_main_title d_box'>
                    {addonGroup.addongroup_name}
                  </p>
                  <h6>You can choose upto {addonItemSelectionMax} {addonItemSelectionMax>1?"options":"option"} </h6>
                  {addonGroup?.addongroupitems?.map((addonItem, index) => (
                    <div className=' addOnItems_block ' key={index}>
                      <div>
                        <input
                          type='checkbox'
                          value={addonItem.addonitem_name}
                          checked={selectedAddons.includes(addonItem)}
                          onChange={() => onUpdateField(addonItem)}
                          className='Addoncustom-checkbox'
                        />
                        <label style={{ paddingLeft: '10px' }}>
                          {addonItem.addonitem_name.slice(0, 30)}
                        </label>
                      </div>

                      <span>Rs {addonItem.addonitem_price}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant='secondary'
              onClick={() => {
                if (!selectedAddons?.length > 0) {
                  setError(`select atleast  ${addonItemSelectionMin} options.`)
                  setShowpopup(true)
                  return
                }
                onAdd(temp, selectedAddons)
                handleClose2()
                setSelectedAddons([])
              }}
              className='add-on-more-btn'
            >
              Add
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showpopup} onHide={handleClose} centered backdrop='static'>
          <Modal.Body className='Mob_Otp_popup' style={{ width: '333px' }}>
            <div style={{ padding: '0' }}>
              <img
                src={process.env.PUBLIC_URL + '/images/icons/cross.png'}
                alt='cross'
                onClick={handleClose}
              />

              <p style={{ color: 'red' }}>{error}</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default Beer
