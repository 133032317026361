import React, { useState,useCallback } from "react";
import Header from "../components/HeaderWeb";
import Footer from "../components/FooterWeb";
import { apiBaseUrl } from "../config/constant";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Cropper from 'react-easy-crop';
// Create this utility function to crop the image
import Slider from '@material-ui/core/Slider';
// import Button from '@material-ui/core/Button';
const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImageSrc(reader.result);
      };
    }
  };

  const getCroppedImg = async (imageSrc, pixelCrop, targetWidth, targetHeight) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          resolve(URL.createObjectURL(blob));
        }, 'image/jpeg');
      };
      image.onerror = () => {
        reject(new Error('Failed to load the image'));
      };
    });
  };

  const onCrop = async () => {
    try {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels, 1200, 1200);
      setCroppedImage(croppedImg);
    } catch (error) {
      console.error('Error cropping the image:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation
    let errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    } else if (/\s/.test(formData.name)) {
      errors.name = "Name should not contain spaces";
    } else if (!/^[A-Za-z]+$/.test(formData.name)) {
      errors.name = "Name must contain only letters";
    } else if (formData.name.length < 4) {
      errors.name = "Name must be at least 4 characters long";
    }
    
    // Validate email
    if (!formData.email) {
      errors.email = "Email is required";
    }
    
    // Validate phone (10 digits, numbers only, no leading 0)
    if (!formData.phone) {
      errors.phone = "Phone is required";
    } else if (!/^[1-9]\d{9}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 10 digits and cannot start with 0.";
    }
    
    // if (!formData.message) {
    //   errors.message = "Message is required";
    // }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      // POST request to the API endpoint
      await axios.post(apiBaseUrl + "common/contact-us", formData);
      handleShow();
      //   alert("Message sent successfully!");
      // Clear form data after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error sending message. Please try again later.");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Header />
        <section className="d_main_panel ">
          <div className="container-fluid">
            <div className="d_create_event d_right_panal box_padding black_heading">
              <div className="d_detail_heading hglight text-center">
                Personal Details
              </div>
              <div className="d_create_event_form login_page">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-12 ">
                      <div className="form-group">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/user.png"
                          }
                          style={{ width: "15px" }}
                        />
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="form-contro p_gray"
                          id="formGroupExampleInput"
                          placeholder="Your name"
                          style={{ disabledBorderColor: " null" }}
                        />
                      </div>
                      {errors.name && (
                        <span className="contact_us_eror">{errors.name}</span>
                      )}
                    </div>
                    <div className="col-md-12 ">
                      <div className="form-group">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/smartphone.png"
                          }
                          style={{ width: "20px" }}
                        />
                        <input
                         type="tel"
                         name="phone"
                         value={formData.phone}
                         onChange={handleChange}
                          className="form-contol p_gray"
                          id="formGroupExampleInput"
                          placeholder="Phone number"
                        />
                      </div>
                      {errors.phone && (
                        <span className="contact_us_eror">{errors.phone}</span>
                      )}
                    </div>
                    <div className="col-md-12   ">
                      <div className="form-group">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/envelope.png"
                          }
                          style={{ width: "13px" }}
                        />
                        <input
                             type="email"
                             name="email"
                             value={formData.email}
                             onChange={handleChange}    
                          className="form-conrol p_gray ml-1"
                          id="formGroupExampleInput"
                          placeholder="Email id"
                        />
                      </div>
                      {errors.email && (
                        <span className="contact_us_eror">{errors.email}</span>
                      )}
                    </div>
                    <div
                      className="col-md-12 d-flex justify-content-center "
                      style={{ marginLeft: "-8.7px" }}
                    >
                      <div className="form-groupp">
                        <textarea
                             name="message"
                             value={formData.message}
                             onChange={handleChange}
                          className="form-contrl p_gray d_textarea"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          cols="33"
                          placeholder="Something about yourself (Optional)"
                          style={{
                            borderRadius: "5px",
                            outline: "none",
                            border: " 1px solid #63636365",
                            padding: "3%",
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div
                      className="col-md-5 text-center"
                      style={{ margin: "10px auto" }}
                    >
                      <button className="d_comn_btn ">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div style={{ padding: '20px' }}>
      <h1>Image Cropper</h1>
      <input type="file" accept="image/*" onChange={onSelectFile} />
      {imageSrc && (
        <div>
          <div
            style={{
              position: 'relative',
              width: '400px',
              height: '400px',
              background: '#333',
              margin: '20px 0',
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={onCropChange}
              onCropComplete={onCropCompleteHandler}
              onZoomChange={onZoomChange}
            />
          </div>
          <div className="controls">
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => onZoomChange(zoom)}
            />
            <Button variant="contained" color="primary" onClick={onCrop}>
              Crop
            </Button>
          </div>
        </div>
      )}
      {croppedImage && (
        <div>
          <h2>Cropped Image</h2>
          <img src={croppedImage} alt="Cropped" style={{ width: '300px', height: '300px' }} />
        </div>
      )}
    </div> */}

      <Footer />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Message Sent!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Message Sent Successfully , Team Will Revert You Back!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
   
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactUs;
