import React, { useState, useEffect,useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Row, Col } from 'react-bootstrap'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Header from '../components/HeaderWeb'
import HeaderMob from '../components/HeaderMob'
import FooterMob from '../components/FooterMob'
import Footer from '../components/FooterWeb'
import { Container } from '@material-ui/core'
import './OrderHistory.css'
import Modal from 'react-bootstrap/Modal'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  setUserCurrLoc,
  getUserCurrLoc,
  getUser,
  getToken
} from '../utils/UserAuthenticate'
import Helper, { setSessionForModal } from '../utils/Helper'
import { DataArray } from '@mui/icons-material'
import moment from 'moment'
import { apiBaseUrl } from '../config/constant'
import { findRestaurantPetpoojaIdByPincode, getAddonsForItem } from '../components/common'
import FnbContext from '../contextApi/FnbContext'

const options = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
}

const YourOrderDetails = () => {
  const {itemsForReorder,resturantDetails} = useContext(FnbContext);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('orderId')
  const [data, setData] = useState([])
  const [orders, setOrders] = useState([])
  const [popUpData, setPopUpData] = useState([])
const [pagination,setPagination]=useState('')
  const [show, setShow] = useState(false)

  const handleClose = item => setShow(false)

  const handleShow = item => {
    setPopUpData(item.response.result)
    setShow(true)
  }
  useEffect(() => {
    if (!getUserCurrLoc()) {
      setUserCurrLoc()
    }
    if (!getUser()) {
      navigate('/login/your-order-history')
    }
    fetchData()
  }, [])

  const fetchData = async (page) => {
    const requestData = {
      email: getUser()?.emailId,
      mobile: getUser()?.mobNum
    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    try {
      const response = await
      axios.post(`${apiBaseUrl}fnb/order/get-order-list?limit=${9}&page=${page??1}`, requestData, config)
        .then(response => {
          const items = response.data.response.results
          setPagination(response.data.response.pagination)
          const promises = items.map(item => {
            return axios
              .get(apiBaseUrl + `fnb/order/detail/${item.id}`)
              .then(response => response.data)
              .then(additionalData => {
                return { ...item, ...additionalData }
              })
          })
          Promise.all(promises).then(results => {
            // Here, `results` is an array of objects that combine the data from
            // the first API and the second API, which can be rendered together.
            setOrders(results)
            const items = results.flatMap(result => result.response.result)
            setData(items)
          })
        })
    } catch (error) {
      console.error(error)
    }
  }
// console.log(orders,"rdereditems ")

const handleReorder = async ( prevOrderData) => {
  navigate('/beer');
  return;
  const pincode=prevOrderData.response.result[0].pincode
  if(pincode && pincode.length === 6){
    const item = await findRestaurantPetpoojaIdByPincode(pincode);
    if(item?.latitude && item?.latitude){
      const data = {
        latitude: item.latitude,
        longitude: item.longitude,
      };
      setSessionForModal("longitude", data);
    }
};

const cartItems = prevOrderData?.response?.result.map(item => {
  // Find the matching response for the current item
  const matchingResponse = itemsForReorder?.items.find(response => String(response.itemid) === String(item.itemId));
  if (matchingResponse) {
    const addons = getAddonsForItem(item.itemid, matchingResponse.addon,JSON.parse(item.addon));
    return { item, addons };
} else {
    return { item, addons: [] }; // No addons for this item
}
}

);
}

  return (
    <>
      <div className='container-fluid'>
        <div className='ph-none'>
          <Header />
        </div>
        <div className='ds-n'>
          <HeaderMob />
        </div>

        <Row>
          {orders &&
            orders.map((item, index) => (
              <Col xs={12} sm={12} md={4} key={index}>
                <div className='card-order_details'>
                  <div className='d-flex'>
                    <img
                    src={item?.response?.result[0]?.image?item?.response?.result[0]?.image:process.env.PUBLIC_URL + '/images/food.webp'}
                      // src={process.env.PUBLIC_URL + '/images/food.webp'}
                      alt='your order icon'
                    />

                    <div style={{ margin: '2%' }}>
                      {item.response.result
                        .slice(0, 1)
                        .map(item =>
                          item.status === 1 ? (
                            <span id='deliver-status'> ORDER ACCEPTED</span>
                          ) : item.status === 4 ? (
                            <span id='deliver-status'> ORDER DISPATCHED </span>
                          ) : item.status === 5 ? (
                            <span id='deliver-status'> Food READY </span>
                          ) : item.status === 10 ? (
                            <span id='deliver-status'> ORDER DELIVERED</span>
                          ) : item.status === -1 ? (
                            <span id='deliver-status'> ORDER CANCELLED </span>
                          ) : (
                            <span id='deliver-status'> ORDER ACCEPTED</span>
                          )
                        )}

                      <p id='order-items' style={{ overflow: 'hidden' }}>
                        {' '}
                        {item.response.result
                          .map((insideItem, index) => (
                            <>
                              {index === 0 ? '' : ','} {insideItem.name}{' '}<br/>
                            </>
                          ))}{' '}
                      </p>
                    </div>
                  </div>
                  <div className='my-divider'></div>
                  <div className='d-flex' style={{ fontSize: '18px' }}>
                    <LocationOnIcon style={{ fontSize: '22px' }} />{' '}
                    {item.address}
                  </div>
                  <div className='dt-price mt-4 mb-4'>
                    <div>
                      {new Intl.DateTimeFormat('en-US', options).format(
                        new Date(item.updated_at)
                      )}
                    </div>
                    {/* <div>{`Rs ${item.response.result
                      .reduce((acc, item) => acc + item.price, 0)
                      .toFixed(2)}`}</div> */}
                    <div>{item.totalAmount}</div>
                  </div>

                  <div className='btn-reorder d-flex justify-content-between'>
                    <span onClick={() => handleShow(item)}>View</span>

                    <span style={{ paddingLeft: '44px' }}  onClick={() => handleReorder(item)}>
                      {' '}
                  
                        Reorder{' '}
                 
                    </span>
                  </div>
                </div>
              </Col>
            ))}

          {/* <Col xs={12} sm={12} md={4}>
  <div className='card-order_details'>
<div className='d-flex'>
<img src={process.env.PUBLIC_URL + '/images/food.webp'} alt="your order icon"/>

<div style={{margin:"2%"}}>
<span id="deliver-status" > ORDER DELIVERED</span><br/>

<p id='order-items'>2X Algeno Passta , Juice ,Ice-cream , fruits , pizza ... </p>
</div>


</div>
<div className='my-divider'></div>
<div className='d-flex' style={{fontSize:"18px"}}>
<LocationOnIcon style={{fontSize:"22px"}}/> 801 , Dadar Mumbai Maharastra ,40001
</div>
<div className='dt-price mt-4 mb-4'>
<div>
  22 March 2023 at 10:00 PM
</div>
<div>Rs 2000.00/</div>
</div>

<div className="btn-reorder d-flex justify-content-between">
  <span>View</span>
  <span style={{paddingRight:"21%"}}>Reorder</span>
</div>
  </div>
</Col> */}
        </Row>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', padding: '20px 0' }}>
    {pagination.prevPage && (
        <button 
            onClick={() => fetchData(pagination.prevPage)} 
            style={{
              padding: '1px 20px',
              backgroundColor: '#a2c760',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#a2c760'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#a2c760'}
        >
            Previous
        </button>
    )}

    <span style={{ fontSize: '16px', color: '#333' }}>
        Page {pagination.currentPage} of {pagination.totalPages}
    </span>

    {pagination.nextPage && (
        <button 
            onClick={() => fetchData(pagination.nextPage)} 
            style={{
                padding: '1px 20px',
                backgroundColor: '#a2c760',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#a2c760'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#a2c760'}
        >
            Next
        </button>
    )}
</div>

        <div className='ds-n mt-4 pt-4'>
          <FooterMob style={{ marginTop: '120px' }} />
        </div>
      </div>

      <div className='ph-none'>
        <Footer />
      </div>

      <Modal show={show} onHide={handleClose} style={{paddingBottom:"0px"}}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"18px",padding:"1px 1px 0px 1px"}}>
            Order id {popUpData.slice(0, 1).map((item) => item.orderId)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Row>
            <Col>
          
                <div>
                  <div className='d_title_box'>
                    <p className='d_main_sub_title' style={{marginBottom:"0px"}}>
                      Your ordered {popUpData.length > 1 ?"items":"item" } <span className='d_line'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span>
                      <span className='d_round'></span>
                    </p>
                    {/* {popUpData && (
                      <h6>{popUpData.length} {popUpData.length > 1 ?"items":"item" } in this order</h6>
                    )} */}
                  <div style={{maxHeight: "200px", overflow: "scroll"}}>
  {popUpData &&
    popUpData.map((item, index) => (
      <Row className="mt-3" key={index} style={{ width: "100%" }}>
        <Col xs={2}>
          <img
            src={item?.image ?? 'images/food5.png'}
            style={{ width: '70px' }}
          />
        </Col>
        
        <Col xs={6}>{item.name}</Col>
        <Col xs={3}>
          {item.quantity} × {item.price}
        </Col>
        <Col xs={1}>{item.finalPrice}</Col>
      </Row>
    ))}
</div>

                 

                    {/* <Row className='mt-4'>
                        <Col>
<img src='images/food5.png' style={{width:"40px"}}/>
                        </Col>

                        <Col>
                            Breakfast thali 
                        </Col>
                        <Col>
                           1 × 100
                        </Col>
                        <Col>
                            Rs 100
                        </Col>
                     </Row> */}

                    <div className='d_title_box' style={{paddingRight:"3%"}}>
                      <p className='d_main_sub_title' style={{margin:"0px"}}>
                        Bill Details<span className='d_line'></span>
                        <span className='d_round'></span>
                        <span className='d_round'></span>
                        <span className='d_round'></span>
                      </p>

                      <div className='d-flex justify-content-between'>
                        <span >Price</span>
                        <span>
                          {' '}
                          {` ${
                            popUpData &&
                            popUpData
                              .reduce((acc, item) => acc + item.price, 0)
                              
                          }`}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span>Product discount</span>
                        <span>
                          {popUpData
                            .slice(0, 1)
                            .map((item) =>
                              item ? item.totalDiscount : '0'
                            )}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span>Delivery charges</span>
                        <span>
                          {popUpData
                            .slice(0, 1)
                            .map((item) => item.deliveryCharges)}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span>Total Amount</span>
                        {/* <span>{`Rs ${
                          popUpData &&
                          popUpData
                            .reduce((acc, item) => acc + item.price, 0)
                            .toFixed(2)
                        }`}</span> */}
                        <span>
                          {' '}
                          {popUpData
                            .slice(0, 1)
                            .map((item) => item.totalAmount)}
                        </span>
                      </div>

                      <p className='d_main_sub_title mt-2' style={{margin:"0px"}}>
                        Order Details<span className='d_line'></span>
                        <span className='d_round'></span>
                        <span className='d_round'></span>
                        <span className='d_round'></span>
                      </p>
                      <div className='order-user-detail'>
                        <p>
                          <span>Order Id</span>
                          <p style={{margin:"0px"}}>
                            {popUpData
                              .slice(0, 1)
                              .map((item, index) => item.orderId)}
                          </p>
                          <p style={{margin:"0px"}}>
                            <span>Payment</span>
                            {popUpData
                              .slice(0, 1)
                              .map((item, index) =>
                                item.paymentType == '1' ? (
                                  <p style={{margin:"0px"}}>Online mode</p>
                                ) : (
                                  <p  style={{margin:"0px"}}>Offline Mode(COD)</p>
                                )
                              )}
                          </p>
                  
                        <p  style={{margin:"0px"}}>
                          <span style={{margin:"0px"}}>Order placed</span> 
                          {popUpData.slice(0, 1).map((item, index) => {
                            const formattedDateTime = moment(item.date).format(
                              'DD MMMM YYYY hh:mm:ss A'
                            )
                            return <div key={index}>{formattedDateTime}</div>
                          })}
                          {/* {new Intl.DateTimeFormat('en-US', options).format(new Date(popUpData[0]?.updated_at))} */}
                        </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
          
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Link to='/beer'>
            <Button variant='primary'>Reorder</Button>
          </Link>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default YourOrderDetails
